import styled from 'styled-components';

export const PaymentPageWidthWrapper = styled.div`
  margin: 0 calc((100% - 100vw) / 2);
  width: 100vw;
`;

export const PaymentIframe = styled.iframe`
  border: none;
`;
